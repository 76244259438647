import Vue from 'vue'
import App from './App.vue'
import router from "./router";
// 全局css
import "./assets/style.css";

// 引入rem适配
import "amfe-flexible";
// 按需引入vent组件
import "@/utils/vant.js";
Vue.config.productionTip = false



new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
