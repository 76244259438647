<template>
  <div>
    <van-tabbar v-model="active" inactive-color="#fff" active-color="#93C320">
      <van-tabbar-item
        v-for="item in tabbarList"
        :key="item.title"
        @click="changeDialogShow(item.title)"
      >
        <i class="tabbar-title">{{ item.title }}</i>
        <template #icon="props" >
          <img :src="props.active ? item.active : item.normal" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <!-- 弹窗 -->
    <Dialogshow
      :dialogshow="dialogshow"
      @closedialog="closedialog"
      :tabbartitle="tabbartitle"
      v-show="tabbartitle !== '首页'"
    >
    </Dialogshow>
  </div>
</template>

<script>
import Dialogshow from "./dialog.vue";

export default {
  components: {
    Dialogshow
  },
  data() {
    return {
      active: 0,
      tabbarList: [
        {
          title: '首页',
          path: { name: 'home' },
          normal: require("@/assets/2x/icon_首页_未选中@2x.png"),
          active: require("@/assets/2x/icon_首页_选中@2x.png"),
        },
        {
          title: '电话',
          normal: require("@/assets/2x/icon_电话_未选中@2x.png"),
          active: require("@/assets/2x/icon_电话_选中@2x.png"),

        },
        {
          title: '微信',
          normal: require("@/assets/2x/icon_微信_未选中@2x.png"),
          active: require("@/assets/2x/icon_微信_选中@2x.png"),
        },
        {
          title: '邮箱',
          normal: require("@/assets/2x/icon_邮箱_未选中@2x.png"),
          active: require("@/assets/2x/icon_邮箱_选中@2x.png"),
        }
      ],
      dialogshow: false,
      tabbartitle: ''
    };
  },
  methods: {
    closedialog(value) {
      this.dialogshow = value

    },
    changeDialogShow(title) {

      // 重复点击路由会卡顿
      if (title !== '首页') {
        // 打开弹窗
        this.dialogshow = true
      }
      else {
        this.$router.push('/')
      }

      // if (title == '电话') {
      //   window.location.href = "tel://13908498614";
      //   // 打开弹窗
      //   // this.dialogshow = true
      // }
      // tabbar标题
      this.tabbartitle = title
    }
  }
};
</script>

<style lang="less" scoped>
.van-hairline--top-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 98px;
  background-color: #0a6635;
}
.van-tabbar-item {
  background-color: #0a6635;
}
.tabbar-title {
  font-size: 20px;
}
</style>
