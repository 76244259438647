<template>
  <div id="app">
    <!-- 包含下拉菜单 -->
    <NavigationBar></NavigationBar>
    <Banner />
    <!-- 内容区域 -->
    <div id="content">
      <router-view />
    </div>

    <Tabbar></Tabbar>
    <!-- 菜单 -->
  </div>
</template>

<script>
import Tabbar from "@/components/tabbar.vue";
import NavigationBar from "@/components/navigationBar.vue";
import Banner from "@/components/banner.vue";
export default {
  name: 'App',
  created() {
  },
  components: {
    Tabbar,
    NavigationBar,
    Banner,
    // dropdownMenu
  },
  mounted() {
    console.log('route', this.$route.path);
  }



}

</script>
<style lang="less" scoped>
#content {
  padding: 30px 30px 128px 30px;
}
</style>