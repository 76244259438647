// 按需引入vent
// 导入vue
import Vue from 'vue'

// 引入组件
import { Button, Tabbar, TabbarItem, Icon, Swipe, SwipeItem, Dialog, Overlay, DropdownMenu, DropdownItem, Cell } from 'vant'

// 使用组件
Vue.use(Button)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Icon);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Dialog);
Vue.use(Overlay);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Cell);

