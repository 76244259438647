<template>
  <div>
    <!-- 公共图 -->
    <van-swipe :autoplay="3000">

      <van-swipe-item v-for="item in responseData" :key="item.carouselCode">
        <img v-if="item.imageState == 0" class="bannerimg" :src="item.imageUrl" />

        <!--<video v-else  controls playsinline autoplay="autoplay" width="100%" >-->
        <video v-else  controls playsinline width="100%" height= "100%">
          <source :src="item.imageUrl" />
        </video>

      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>

import axios from 'axios'
export default {
  data() {
    return {
      responseData: [] // 存放返回的数据
    }
  },
  created() {
    this.fetchData(); // 调用方法获取数据
  },

  methods: {
    fetchData() {
      axios.get('http://www.jin-quan.tech/prodnew-api/system/carouselMap/hunan') // 这里是API地址，根据自己的情况修改
        .then(response => {
          this.responseData = response.data.data; // 将返回的数据保存到变量中

          if (this.responseData.length > 0) {
            this.responseData.forEach(e => {
              e.imageUrl = e.imageUrl.replace('/prodnew-api', '');
              // https://backend-web.obs.cn-north-4.myhuaweicloud.com/image/1705564205923.png
              e.imageUrl = "https://backend-web.obs.cn-north-4.myhuaweicloud.com" + e.imageUrl
            })
            console.log(JSON.stringify(response.data.data))
          }
        })
        .catch(error => {
          console.log(error); // 处理错误信息
        });
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .van-swipe {
  margin-top: 130px;
  height: 350px;
  .bannerimg {
    width: 100%;
    height: 100%;
  }
}
</style>
