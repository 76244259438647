<template>
  <div>
    <van-overlay :show="dialogshow" @click="closedialog" />
    <!-- 确认取消按钮 -->
    <van-dialog
      @cancel="closedialog"
      @confirm="callPhone"
      v-model="changedialogshow"
      :style="{ height: tabbartitle == '微信' ? '300px' : '200px' }"
      :show-cancel-button="tabbartitle !== '微信'"
      :showConfirmButton="tabbartitle !== '微信'"
      :overlay="false"
    >
      <div slot="title" v-if="tabbartitle !== '微信'">
        <div>提示</div>
      </div>

      <div slot="default" style="height: 100%">
        <div class="default-text" v-show="tabbartitle == '电话'">
          确定拨打电话：{{contactus.corpTelephone}}吗？
        </div>
        <div class="default-text" v-show="tabbartitle == '邮箱'">
          公司邮箱：{{contactus.corpEmail}}
        </div>
        <div class="default-text weixin" v-show="tabbartitle == '微信'">
          <div class="weixindiv">
            <img
              :src="'http://www.jin-quan.tech/'+contactus.wecatImage"
              alt=""
              v-show="tabbartitle == '微信'"
              class="weixinimg"
            />

            <p class="weixintext">关注微信公众号</p>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>


<script>
  import axios from 'axios'

export default {
  mounted() {
    this.getContactusData()
  },
  data() {
    return {
      changedialogshow: this.dialogshow,
      contactus: {}

    }
  },
  props: {
    dialogshow: {
      type: Boolean
    },
    dialoghight: {
      type: Number
    },
    tabbartitle: {
      type: String
    }
  },

  methods: {
    closedialog() {
      this.$emit('closedialog', false)
    },
// 调用拨号功能
    callPhone(phoneNumber) {

      if(this.tabbartitle == '电话') {
        window.location.href = "tel:"+this.contactus.corpTelephone;
      }
      this.$emit('closedialog', false)
    },

    getContactusData() {
      axios.get('http://www.jin-quan.tech/prodnew-api/system/contactUs/getDetails/1') // 这里是API地址，根据自己的情况修改
              .then(response => {
                this.contactus = response.data.data
              })
              .catch(error => {
                console.log(error); // 处理错误信息
              });
    }

  },
  // Vue2中移除了组件的props的双向绑定
  watch: {
    dialogshow(val) {
      this.changedialogshow = val;//监听变更并同步到changedialogshow上
    }
  },
};
</script>

<style lang="less" scoped>
::v-deep.van-dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  height: 260px;
}

::v-deep .van-button__text {
  color: #333;
  font-size: 38px;
  font-weight: 500;
}
::v-deep .van-dialog__confirm {
  .van-button__text {
    color: #5e6889;
  }
}

.weixin {
  .weixindiv {
    position: absolute;
    left: 22%;
    top: 50px;
    .weixinimg {
      width: 368px;
      height: 368px;
      align-items: center;
    }
    .weixintext {
      font-size: 38px;
      font-weight: 500;
      text-align: center;
    }
  }
}

/* 提示框内容 */
.default-text {
  text-align: center;
  width: 100%;
  font-size: 32px;
  font-weight: 500;
  color: #999;
}

/* //提示框标题 */
.default-title {
  font-weight: 500;
  font-size: 38px;
}

.van-overlay {
  width: 100%;
  height: 100%;
  z-index: 99;
}
</style>
