<template>
  <div>
    <!-- logo+导航分类图标 -->
    <div class="logo">
      <img class="logoimg" src="../assets/2x/金全环保_logo@2x.png" />

      <van-dropdown-menu
        :close-on-click-overlay="false"
        :close-on-click-outside="false"
        :overlay="false"
      >
        <van-dropdown-item
          @open="opendropdown"
          @close="closedropdown"
          ref="gender"
        >
          <template #title>
            <i class="navigation"></i>
          </template>
          <template #default>
            <van-cell v-for="item in listPath" :key="item.path">
              <router-link
                :to="item.path"
                :class="item.path == routeName ? 'select' : 'navigation-text'"
              >
                {{ item.name }}
              </router-link>
            </van-cell>
          </template>
        </van-dropdown-item>
      </van-dropdown-menu>
    </div>
    <van-overlay :show="showoverlay" @click="clickoverlay" />
  </div>
</template>

<script>
export default {

  data() {
    return {
      // dropdownMenu: true,
      // 收集路由
      routeName: '',
      // 蒙版
      showoverlay: false,
      // 路由
      listPath: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '关于我们',
          path: '/aboutUs'
        },
        {
          name: '核心业务',
          path: '/core'
        },
        {
          name: '金全资讯',
          path: '/information'
        },
        {
          name: '联系我们',
          path: '/contactUs'
        },
      ],
      // 蒙版点击次数
      clickoverlaynumber: 0
    }
  },
  methods: {
    // 下拉菜单打开时
    opendropdown() {
      this.showoverlay = true
    },
    // 点击遮罩层
    clickoverlay() {
      this.clickoverlaynumber++
      // 判断是否点了两次
      if (this.clickoverlaynumber == 2) {
        this.clickoverlaynumber = 0
        this.showoverlay = false
        this.$refs.gender.toggle(false)
      }
    },
    // 下拉菜单关闭时
    closedropdown() {
      this.showoverlay = false
      this.$refs.gender.toggle(false)
    }
  },
  watch: {
    $route: {
      // 检测路由
      handler(newVal, oldVal) {
        this.routeName = newVal.fullPath
        this.showoverlay = false
        this.$nextTick(() => {
          this.$refs.gender.toggle(false)

        })
      },
      deep: true,
      immediate: true,
    }
  }
}
</script>

<style lang="less" scoped>
.logo {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  width: 100%;
  height: 120px;
  background-color: #fff;
  z-index: 50;
}
::v-deep.navigation {
  display: inline-block;
  width: 70px;
  height: 70px;
  background: url("../assets/2x/icon_导航分类@2x.png");
  background-size: contain;
}

.logoimg {
  width: 330px;
  height: 90px;
}

::v-deep .van-dropdown-menu__bar {
  box-shadow: none !important;
}
::v-deep .van-dropdown-item__content {
  position: absolute;
  max-height: 100%;
}
::v-deep .van-dropdown-item--down {
  height: 34%;
}

::v-deep .van-cell__value {
  margin-top: 8px;
  margin-bottom: 8px;
}

::v-deep .van-dropdown-menu__title {
  &::after {
    display: none;
  }
}
.navigation-text {
  font-size: 30px;
  color: #666666;
  font-weight: 500;
}
//选中的颜色
.select {
  font-size: 30px;
  color: #0a6635;
  font-weight: 500;
}
</style>
