import Vue from 'vue'
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [

  {
    path: '/',
    name: 'home',
    component: () => import('@/view/home/index.vue')
  },

  {
    path: "/aboutUs",// 关于我们
    name: "aboutUs",
    component: () => import('../view/navigation/aboutUs/index.vue'),
  },
  {
    path: '/core', //核心业务
    name: 'core',
    component: () => import('../view/navigation/core/index.vue')
  },
  {
    path: '/information', //金全咨询
    name: 'information',
    component: () => import('../view/navigation/information/index.vue'),
  },
  {
    path: '/contactUs', //联系我们
    name: 'contactUs',
    component: () => import('../view/navigation/contactUs/index.vue')
  },

  {
    path: '/news',// 新闻
    name: 'news',
    component: () => import('../view/navigation/news/index.vue'),

  }

]


const router = new VueRouter({
  routes,

})
// 进路由器把页面滚到最顶部
router.beforeEach((to, from, next) => {

  document.body.scrollTop = 0; // firefox
  document.documentElement.scrollTop = 0; // safari
  window.pageYOffset = 0; // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁

  next()
});

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router
